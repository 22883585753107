@import './../../styles/variables';
@import './../../styles/mixins';

.tile {

  &-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    text-align: center;
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), $introduction-tile-bgcolor);

    @include respond-to('medium') {
      padding: 10px;
    }

    .avatar {
        height: 150px;
        width: 150px;
        border-radius: 50%;
    }

    .site-links {
      padding: 10px 0;

      i {
        font-size: 2em;
        margin: 0 10px;
      }
    }

    h1 {
      text-transform: uppercase;
      letter-spacing: 3px;
      text-align: justify;
    }
    
    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      word-break: break-word;
    }
  }
}
