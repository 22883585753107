@import "./variables";
@import "./mixins.scss";

.app {
  &-container {
    width: 60%;
    margin: auto;

    .tile {
      color: #fff;
      margin: 25px;
      border-radius: 5px;
    }

    @include respond-to("medium") {
      width: 100%;
    }
  }
}
