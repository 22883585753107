@import "./../../styles/variables";
@import "./../../styles/mixins";

.tile {
  &-skills {
    border-radius: 5px;
    background-color: $skills-tile-bgcolor;
    padding: 25px;

    h3 {
      text-align: center;
      margin: 0 0 10px 0;
    }

    .tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      div {
        border: 1px solid;
        border-radius: 10px;
        margin: 10px 10px 0 0;
        padding: 10px;
      }
    }
  }
}
