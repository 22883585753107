@import "./../../styles/variables";
@import "./../../styles/mixins";

$colors: #e94e36, #F7AF1F, #4CB9AF, #BF407F, #009688, #b73816, #d46685, #006F89, #546f51, #FF732F, #1bb7c3, #c09b6d, #ba4f5d, #8b1fca, #c5ba02, #b73816, #d46685, #006F89, #1bb7c3;

@mixin timeline-spot-content($bgColor) {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $bgColor;
  z-index: 1;
  border: 4px solid #fff;
  margin-bottom: -14px;
}

@mixin timeline-background-color {
  @for $i from 2 through length($colors) {
    &:nth-child(#{$i}) {
      $bgColor: nth($colors, $i);
      .card {
        &-01 {
          background-color: $bgColor;

          h3 {
            &::after {
              @include timeline-spot-content($bgColor);
            }
          }
        }
        &-02 {
          background-color: $bgColor;

          h3 {
            &::before {
              @include timeline-spot-content($bgColor);
            }
          }
        }
      }
    }
  }
}

@mixin timeline-tile-background-color {
  @for $i from 2 through length($colors) {
    &:nth-child(#{$i}) {
      .card {
        &-01 {
          background-color: nth($colors, $i);

          h3 {
            &::after {
              display: none;
            }
          }
        }
        &-02 {
          background-color: nth($colors, (length($colors) + 1 - $i));

          h3 {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.tile {
  @include timeline-background-color;
  &-title {
    margin: 10px 0;
    border-bottom: 0;
    text-align: center;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: $timeline-tile-bgcolor;
    padding: 10px;
  }

  &-timeline {
    display: flex;
    flex-direction: column;
    padding: 4px 0;
  }

  &-content {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  &-card {
    padding: 25px 0 25px;
    width: 50%;
    background-color: $timeline-tile-bgcolor;

    p,
    i {
      margin: 10px 0;
    }

    h4 {
      padding: 0 25px;
      margin: 10px 0;
    }
  }

  .card {
    &-header {
      border-bottom: 4px solid #fff;
    }

    &-project {
      margin: 0 25px 25px;
      border-bottom: 1px solid;
      padding-bottom: 25px;

      i {
        display: block;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &-01 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: right;
      border-right: 4px dotted #fff;
      border-radius: 5px 0 0 5px;

      h3 {
        z-index: 1;
        &::after {
          @include timeline-spot-content($timeline-tile-bgcolor);
          margin-right: -8px;
        }
      }
    }

    &-02 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: left;
      border-radius: 0 5px 5px 0;

      h3 {
        &::before {
          @include timeline-spot-content($timeline-tile-bgcolor);
          margin-left: -8px;
        }
      }

      .empty-container {
        height: 80px;
      }
    }
  }

  &-separator {
    border-left: 4px dotted white;
  }

  @include respond-to("medium") {
    @include timeline-tile-background-color;
    &-content {
      flex-direction: column;
    }

    &-card {
      width: 100%;
    }

    .card {
      &-header {
        text-align: center;
      }

      &-project {
        margin: 0 25px;
      }

      &-01 {
        text-align: justify;
        border-right: 0;
        margin-bottom: 25px;
        border-radius: 5px;
        h3 {
          &::after {
            display: none;
          }
        }
      }

      &-02 {
        text-align: justify;
        border-radius: 5px;

        h3 {
          &::before {
            display: none;
          }
        }

        .empty-container {
          display: none;
        }
      }
    }
  }
}
